import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RulesService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get(`https://api-prod.kharaaj.com/api/v1/rules`);
  }
}
