import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient) {}

  getAll() {
    return this.http.get(`https://api-prod.kharaaj.com/api/v1/articles`);
  }

  getLatest() {
    return this.http.get(`https://api-prod.kharaaj.com/api/v1/articles/latest`);
  }

  getOne(articleId: string) {
    return this.http.get(`https://api-prod.kharaaj.com/api/v1/articles/${articleId}`);
  }
}
